.fade-in-text {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  font-size: 70px; /* Default font size */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 200px;
  position: relative;
  margin-top: 50px; /* Add margin to move content down */
}

.fade-in-link {
  opacity: 0;
  animation: fade-in-link 0.5s ease-in-out forwards;
}

@keyframes fade-in-link {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.decorated-button {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
  border: none;
  width: 100px;
}

.button-image img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.button-image img:hover {
  filter: grayscale(0%);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .home.container {
    height: auto;
    margin-top: 125px;
  }

  .links {
    position: static;
    flex-wrap: wrap;
    justify-content: center;
  }

  .decorated-button {
    margin: 10px;
    width: 75px;
  }

  .fade-in-text {
      font-size: 45px; /* Default font size */
    }
}
