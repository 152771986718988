.top-artist-element {
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #f2f2f2;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .artist-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 12px;
  }
  
  .artist-info {
    flex-grow: 1;
  }
  
  .artist-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .artist-name a {
    color: #000000;
    text-decoration: none;
  }
  
  .artist-name a:hover {
    text-decoration: underline;
  }
  